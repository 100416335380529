.Footer-container{
    position: relative;
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);

}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links > img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.log-f>img{
    width: 10rem;
    
}
@media screen and (max-width:850px){
    
}